import React, { useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { setLoading, setSnackbarMsg } from "../../../store/reducers/misc";
import { editProfile } from "../../../lib/request";
import { useSearchParams } from "react-router-dom";

const StyledDiv = styled(Box)`
  ${({ theme }) => `
		padding: ${theme.spacing(2)};    
  `}
`;

export default function Tab1(props) {
  const { profile } = props;
  console.log(profile);

  const dispatch = useDispatch();

  const { me } = useSelector((state) => state.profile);
  const { brandInFocus } = useSelector((state) => state.profile);

  const [form, setForm] = useState({
    id: profile.id,
    email: profile.email,
    phone: profile.phone ? profile.phone : "",
    gEmail: profile.gEmail ? profile.gEmail : "",
    appleEmail: profile.appleEmail ? profile.appleEmail : "",
    appleSub: profile.appleSub ? profile.appleSub : "",
    verify: _.isBoolean(profile.verify) ? profile.verify : false,
  });

  const q = { bId: brandInFocus };

  function changeForm(e) {
    const { name, value } = e.target;
    const formClone = _.clone(form);
    formClone[name] = value;
    setForm(formClone);
  }

  function changeSwitch(e, value) {
    const { name } = e.target;
    const formClone = _.clone(form);
    formClone[name] = value;
    setForm(formClone);
  }

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (!/U/.test(me.permission)) {
      return null;
    }

    const payload = _.pick(form, ["id", "email", "gEmail", "appleEmail", "appleSub", "phone", "verify"]);

    dispatch(setLoading(true));
    editProfile(payload.id, payload, q)
      .then(() => dispatch(setSnackbarMsg("Profile Updated")))
      .catch((err) => {
        if (err.response) {
          dispatch(setSnackbarMsg(err.response.data.message));
        }
      })
      .finally(() => dispatch(setLoading(false)));
  }

  return (
    <StyledDiv>
      <Grid container spacing={2}>
        <Grid xs={12} sm={12} md={5} lg={4}>
          <Paper sx={{ p: 2 }} variant={"outlined"}>
            <form onSubmit={onSubmit}>
              <Typography variant={"h6"} sx={{ pb: 2 }}>
                Changeable information
              </Typography>
              <TextField
                fullWidth
                margin={"normal"}
                name={"email"}
                value={form.email}
                onChange={changeForm}
                label={"Email"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: !/U/.test(me.permission) }}
              />
              <TextField
                fullWidth
                margin={"normal"}
                name={"phone"}
                value={form.phone}
                onChange={changeForm}
                label={"Phone number"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: !/U/.test(me.permission) }}
              />
              <TextField
                fullWidth
                margin={"normal"}
                name={"gEmail"}
                value={form.gEmail}
                onChange={changeForm}
                label={"Google Email"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: me.isSuperAdmin === false }}
              />
              <TextField
                fullWidth
                margin={"normal"}
                name={"appleEmail"}
                value={form.appleEmail}
                onChange={changeForm}
                label={"Apple Email"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: me.isSuperAdmin === false }}
              />
              <TextField
                fullWidth
                margin={"normal"}
                name={"appleSub"}
                value={form.appleSub}
                onChange={changeForm}
                label={"Apple Sub"}
                InputLabelProps={{ shrink: true }}
                InputProps={{ readOnly: me.isSuperAdmin === false }}
              />
              <FormGroup>
                <FormControlLabel label="Account is verified" control={<Switch name={"verify"} checked={form.verify} onChange={changeSwitch} />} />
              </FormGroup>
              {profile.isOptOut === false && (
                <Button type={"submit"} fullWidth sx={{ mt: 2 }} variant={"outlined"} disabled={!/U/.test(me.permission)}>
                  Save
                </Button>
              )}
            </form>
          </Paper>
        </Grid>
        <Grid xs={12} sm={12} md={7} lg={8}>
          <Paper sx={{ p: 2 }} variant={"outlined"}>
            <Typography variant={"h6"} sx={{ pb: 2 }}>
              Account information
            </Typography>
            <TextField
              fullWidth
              margin={"normal"}
              label={"Last login"}
              defaultValue={profile.lastLogged ? dayjs(profile.lastLogged).format("DD MMMM YYYY h:mm A") : "-"}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
            <TextField
              fullWidth
              margin={"normal"}
              label={"Register date"}
              defaultValue={dayjs(profile.createdAt).format("DD MMMM YYYY h:mm A")}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true }}
            />
          </Paper>
        </Grid>
      </Grid>
    </StyledDiv>
  );
}

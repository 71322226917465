import React, {useEffect, useState} from 'react'
import _ from 'lodash'
import {useDispatch, useSelector} from 'react-redux'

import {Link, useParams, useNavigate} from 'react-router-dom'
import {styled, useTheme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Button from '@mui/material/Button'
import useMediaQuery from "@mui/material/useMediaQuery";

import DeleteConfirmationDialog from './delete-confirmation-dialog'
import Tab1 from './tab-1'
import Tab2 from './tab-2'
import Tab3 from './tab-3'
import {viewBrand, editBrand, deleteBrand, getAllApplications, getCredentials} from '../../../lib/request'
import {updateBrand, removeBrand} from '../../../store/reducers/brand'
import {setLoading, setSnackbarMsg} from '../../../store/reducers/misc'
import PanelHead from '../../../components/panel-head'
import {setApplications} from '../../../store/reducers/application'
import {setCredentials} from '../../../store/reducers/credentials'

const StyledDiv = styled(Box)`
`

export default function BrandDetail(props) {

	const theme = useTheme()
	const isMobile = useMediaQuery(theme.breakpoints.down('md'))

	const {id} = useParams()
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const applications = useSelector((state) => state.application.applications)
	const credentials = useSelector((state) => state.credential.credentials)
	const networks = useSelector((state) => state.network.networks)
	const {me} = useSelector(state => state.profile)

	const [openTab, setOpenTab] = useState(0)
	const [brandDetail, setBrandDetail] = useState({})
	const [toBeDelete, setToBeDelete] = useState(false)
	const [applicationListing, setApplicationListings] = useState(applications)
	const [form, setForm] = useState({
		id: null,
		name: '',
		nameErr: null,
		orderPrefix: '',
		merchantId: '',
		merchantVerifyKey: '',
		merchantSecretKey: '',
		merchantPaymentUrl: '',
		merchantProxyDomain: '',
		networks: [],
		networkErr: null,
		isNeedVerify: true,
		bypassPassword: false,
	})

	useEffect(() => {

		fetchApplications()

		dispatch(setLoading(true))
		viewBrand(id)
			.then(response => {
				setBrandDetail(response.data)

				const formClone = {...form}
				const obj = _.pick(response.data, ['id', 'name', 'orderPrefix', 'merchantId', 'merchantVerifyKey', 'merchantSecretKey', 'merchantPaymentUrl', 'merchantProxyDomain', 'isNeedVerify', 'bypassPassword'])
				formClone.id = obj.id
				formClone.name = obj.name
				formClone.orderPrefix = _.isEmpty(obj.orderPrefix) ? '' : obj.orderPrefix
				formClone.merchantId = _.isEmpty(obj.merchantId) ? '' : obj.merchantId
				formClone.merchantVerifyKey = _.isEmpty(obj.merchantVerifyKey) ? '' : obj.merchantVerifyKey
				formClone.merchantSecretKey = _.isEmpty(obj.merchantSecretKey) ? '' : obj.merchantSecretKey
				formClone.merchantPaymentUrl = _.isEmpty(obj.merchantPaymentUrl) ? '' : obj.merchantPaymentUrl
				formClone.merchantProxyDomain = _.isEmpty(obj.merchantProxyDomain) ? '' : obj.merchantProxyDomain
				formClone.isNeedVerify = !_.isBoolean(obj.isNeedVerify) ? true : obj.isNeedVerify
				formClone.bypassPassword = !_.isBoolean(obj.bypassPassword) ? true : obj.bypassPassword

				response.data.Networks.forEach(n => {
					formClone.networks.push(n.id)
				})

				formClone.networks = formClone.networks.filter((v, i, s) => s.indexOf(v) === i)

				setForm(formClone)
			})
			.catch(err => {
				if (err.response) {
					const {status, message} = err.response
					dispatch(setSnackbarMsg(message))
					if (status === 404) {
						const formClone = _.cloneDeep(form)
						formClone.id = 404
						setForm(formClone)
					}
				}
			})
			.finally(() => dispatch(setLoading(false)))

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id])

	function onSubmit(e) {
		e.preventDefault()
		e.stopPropagation()

		const formClone = {...form}

		let error = false

		if (_.isEmpty(_.trim(form.name))) {

			formClone.nameErr = 'Name is required'
			error = true
		}
		if (_.isEmpty(form.networks)) {
			formClone.networkErr = 'Network is required'
			error = true
		}

		if (error) {
			return setForm(formClone)
		}

		dispatch(setLoading(true))
		editBrand(form.id, form)
			.then(response => {
				dispatch(updateBrand(response.data))
				dispatch(setSnackbarMsg('Brand updated'))
				navigate('/brand')
			})
			.catch(err => console.error(err.toString()))
			.finally(() => dispatch(setLoading(false)))

	}

	function changeForm(e) {
		const {name, value, checked} = e.target
		const formClone = _.cloneDeep(form)

		if (name === 'isNeedVerify' || name === 'bypassPassword') {
			formClone[name] = checked
		} else {
			formClone[name] = value
			formClone.nameErr = null
		}

		setForm(formClone)
	}

	function changeNetworks(e, v) {
		const {value} = e.target
		const formClone = _.cloneDeep(form)

		const index = formClone.networks.indexOf(parseInt(value))
		if (index === -1) {
			formClone.networks.push(parseInt(value))
		} else {
			formClone.networks.splice(index, 1)
		}

		if(formClone.networks.length > 0) {
			formClone.networkErr = null
		} else {
			formClone.networkErr = 'Network is required'
		}

		setForm(formClone)
	}

	function confirmDelete() {
		dispatch(setLoading(true))
		deleteBrand(form)
			.then(() => dispatch(removeBrand(form)))
			.catch(err => console.error(err.toString()))
			.finally(() => {
				dispatch(setLoading(false))
				setToBeDelete(false)
				navigate('/brand')
			})
	}

	function fetchApplications() {
		getAllApplications()
			.then((res) => {
				dispatch(setApplications(res.data))
				setApplicationListings(res.data)
				return getCredentials()
			})
			.then(response => dispatch(setCredentials(response.data.credentials)))
			.catch(err => console.log(err.response.data.message))
	}

	return (
		<StyledDiv>
			<PanelHead title={brandDetail.name}>
				<Link to={'/brand'}>
					<Button
						fullWidth={isMobile}
						variant={isMobile? 'outlined' : 'text'}
						color={'inherit'}
						sx={{mr: isMobile ? 0 : 2}}>
						Back
					</Button>
				</Link>
				{
					openTab !== 2 && (
						<>
							{
								!_.isEmpty(me) && (me.permission.includes("D")) && (
									<Button
										fullWidth={isMobile}
										color={'error'}
										variant="outlined"
										sx={{mr: isMobile ? 0 : 2, mt: isMobile ? 1 : 0}}
										onClick={() => setToBeDelete(true)}
										disabled={form.id === 404}>
										Delete
									</Button>
								)
							}
							{
								!_.isEmpty(me) && (me.permission.includes("U")) && (
									<Button
										fullWidth={isMobile}
										variant="outlined"
										onClick={onSubmit}
										sx={{mt: isMobile ? 1 : 0}}
										disabled={form.id === 404}>
										Save
									</Button>
								)
							}
						</>
					)
				}
			</PanelHead>

			{
				form.id === 404 && (
					<Alert severity={'error'}>The brand cannot be found</Alert>
				)
			}

			{
				form.id !== 404 && (
					<>
						<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
							<Tabs value={openTab} onChange={(e,t) => setOpenTab(t)} aria-label="basic tabs example">
								<Tab label="Main" />
								<Tab label="Subscription" />
								<Tab label="Applications" />
							</Tabs>
						</Box>
						<Box className={'tabPanel'} role={'tabpanel'} hidden={openTab !== 0}>
							{
								openTab === 0 && (
									<Tab1
										form={form}
										changeForm={changeForm}
										onSubmit={onSubmit}
										networks={networks}
										changeNetworks={changeNetworks}
									/>
								)
							}
						</Box>
						<Box className={'tabPanel'} role={'tabpanel'} hidden={openTab !== 1}>
							{
								openTab === 1 && (
									<Tab2 form={form} changeForm={changeForm} onSubmit={onSubmit} />
								)
							}
						</Box>
						<Box className={'tabPanel'} role={'tabpanel'} hidden={openTab !== 2}>
							{
								openTab === 2 && (
									<Tab3
										applications={applications}
										credentials={credentials}
										brand={brandDetail}
										form={form}
										changeForm={changeForm}
										onSubmit={onSubmit} />
								)
							}
						</Box>
					</>
				)
			}

			{
				toBeDelete && (
					<DeleteConfirmationDialog
						onCancel={() => setToBeDelete(false)}
						onConfirm={confirmDelete}
					/>
				)
			}
		</StyledDiv>
	)
}
